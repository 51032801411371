import React, { Component, Fragment } from 'react';
import { setLocale, frLanguage, enLanguage, getLng } from "../../localization/index";
import './style.scss'

const constTraductions = [
    {img: '/assets/images/traduction/englishTraduction.svg', wording: 'English (US)', shortCode: enLanguage},
    {img: '/assets/images/traduction/frenchTraduction.svg', wording: 'Français', shortCode: frLanguage}
    // {img: 'https://image.flaticon.com/icons/svg/299/299914.svg', wording: '简体中文', shortCode: 'CN'},
    // {img: 'https://image.flaticon.com/icons/svg/299/299786.svg', wording: 'Deutsch', shortCode: 'DE'},
    // {img: 'https://image.flaticon.com/icons/svg/299/299820.svg', wording: 'Español', shortCode: 'ES'},
        
]

class Traduction extends Component {
  constructor(props) {
    super(props)
    this.calendarRef = React.createRef();
    this.state = {
      open: false,
      index: 0
    };
    this.traductions = [...constTraductions]
    if(getLng().indexOf(frLanguage) > -1) {
      this.permutTraductions(1)
    }
    
    }

      closeEventListener = (e) => {
        if (
          this.calendarRef.current &&
          this.calendarRef.current !== e.target &&
          !this.calendarRef.current.contains(e.target)
        )
          this.togglePopover();
      };

  togglePopover = () => {

    if (this.calendarRef === {"current": "div.lang"}
 && this.state.open) return;
    this.setState({ open: !this.state.open }, () => {
      if (this.state.open)
        document.addEventListener('click', this.closeEventListener);
      else
        document.removeEventListener('click', this.closeEventListener);
    });
  }


  firstItemLangage = ({img, shortCode}) => {
    return <Fragment>
             <img src={img} alt="" />
            <span className="lang-txt">{shortCode}</span>      
     </Fragment>
  }


  itemLangage = ({img, wording}, index) => {
    return <div className="lang" onClick={() => this.changeLanguage(index)}>
             <img src={img} alt="" />
            <span className="lang-txt">{wording}</span>      
     </div>
  }

  permutTraductions = (index) => {
    const permut = this.traductions[0]
    this.traductions[0] = this.traductions[index]
    this.traductions[index] = permut
  }

  changeLanguage = (index) => {
    this.setState({index})
    this.permutTraductions(index)
    let language = enLanguage;
    if (this.traductions[0].shortCode === frLanguage) 
      language = frLanguage;
    setLocale(language);

  }


  

  
  render () {
    const activeClass = this.state.open ? 'active' : ''
    return (
      <div className='traduction' >
      <div className={`traduction translate_wrapper ${activeClass}`}>
      <div className="current_lang">
        <div className="lang first_element"  ref={this.calendarRef} onClick={this.togglePopover}>
        {this.firstItemLangage(this.traductions[0])}
        <span className="fa fa-chevron-down chevron"></span>
        </div>
      </div>
      
      
       
        
      
      <div className={`more_lang ${activeClass}`}>
        {this.traductions.map((element, index) => {
          // First Element
          if (!index) {
            return null
          }
          else {
            return this.itemLangage(element, index)
          }
        })}
       
        
        
      </div>
      
      
    </div>
          </div>

    )
  }
}
export default Traduction;