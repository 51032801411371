// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

import DarkPortfolioLanding from './dark/PortfolioLanding';


import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import {setLanguage} from './localization';

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import * as serviceWorker from './serviceWorker';


class Root extends Component{
    ComponentDidlMount() {
        setLanguage();
    }
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`/`} component={DarkPortfolioLanding}/>
                     <Redirect to="/" />
                </Switch>
                <ToastContainer />
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();