import axios from 'axios';


const axiosOptions = {withCredentials: true}


export const sendSupportMail = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_API_GATEWAY}/sendemail`, {...data}, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      axiosOptions
    }).then(response => {
      resolve(response)
    }).catch(e => {
      reject(e)
    })
  })
}

