import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { sendSupportMail } from '../../logic/requests'
import { toast } from 'react-toastify';

class ContactThree extends Component{
    constructor(props){
        super(props);
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
        };
    }

    sendMail = (event) => {
        event.preventDefault()
        const { t } = this.props

        sendSupportMail({...this.state})
        .then(data => {
            toast.success(t('send_mail_toast'), {
                position: toast.POSITION.TOP_RIGHT
              });
        })
        .catch(error => console.log(error))
    }
    render(){
        const { t } = this.props
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">{this.props.contactTitle}</h2>
                                <p className="description">{t('contact_text1')} <a href="tel:+33753853654">0753853654</a> <br /> {t('contact_text2')}
                                    <a href="mailto:ghor.sami@gmail.com"> ghor.sami@gmail.com</a> 
                                    </p>
                            </div>
                            {/* <div className="form-wrapper">
                                <form>
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.name}
                                            onChange={(e)=>{this.setState({name: e.target.value});}}
                                            placeholder={t('name_placeHolder')}
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.email}
                                            onChange={(e)=>{this.setState({email: e.target.value});}}
                                            placeholder={t('email_placeHolder')}
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.subject}
                                            onChange={(e)=>{this.setState({subject: e.target.value});}}
                                            placeholder={t('subject_placeHolder')}
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.message}
                                            onChange={(e)=>{this.setState({message: e.target.value});}}
                                            placeholder={t('message_placeHolder')}
                                        />
                                    </label>
                                    <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe" disabled={!disbaledCondition} onClick={this.sendMail}>{t('contact_submit')}</button>
                                </form>
                            </div> */}
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src={`${this.props.contactImages}`} alt="trydo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation() (ContactThree);
