import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import { withTranslation } from 'react-i18next';
import ModalVideo from 'react-modal-video';
// import FileSaver from 'file-saver';



class PortfolioDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
        this.isBackButtonClicked = true
    }
    openModal () {
        this.setState({isOpen: true})
    }



    componentDidMount() {
        window.scrollTo(0, 0)
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', this.onBackButtonEvent);
      }

   onBackButtonEvent = (e) => {
    e.preventDefault();
    if (this.isBackButtonClicked) {
        this.props.updateGlobalSate()
     }
    this.isBackButtonClicked = true;

  }

    componentWillUnmount = () => {
        window.removeEventListener('popstate', this.onBackButtonEvent);
      }

    //   downloadDocs = () => {
    //     const oReq = new XMLHttpRequest();
    //     // The Endpoint of your server
    //     const URLToPDF = "https://cv-documents.s3.amazonaws.com/cv_Sami_GHORBEL_french.pdf";
    
    //     // Configure XMLHttpRequest
    //     oReq.open("GET", URLToPDF, true);
    
    //     // Important to use the blob response type
    //     oReq.responseType = "blob";
    
    //     // When the file request finishes
    //     // Is up to you, the configuration for error events etc.
    //     oReq.onload = () => {
    //       // Once the file is downloaded, open a new window with the PDF
    //       // Remember to allow the POP-UPS in your browser
    //       const file = new Blob([oReq.response], {
    //         type: 'application/pdf'
    //       });
    
    //       // Generate file download directly in the browser !
    //       FileSaver.saveAs(file, "CV_SAMI_GHORBEL_FRENCH.pdf");
    //     };
    
    //     oReq.send();
    
    //   }
    render(){
        const { t, details } = this.props
        return(
            <React.Fragment>
                <PageHelmet pageTitle={t(details.title)} />

                <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"  detailsProject={true} updateGlobalSate={this.props.updateGlobalSate}/>
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--details-project"  data-black-overlay="3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">{t(details.title)}</h2>
                                    <p>{t(details.category)} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 style={{fontSize: "50px"}}>Description :
                                        {' '}
                                        {details.link && <a href={details.link} target="_blank" rel="noopener noreferrer" className="link-description-project" >{t('link')}</a>}
                                        </h3>
                                        <p>{t(details.description)}</p>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                      {details.video && <div className="thumb position-relative mb--30">
                                            <img src={t(details.image)} alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='bLYayTpVt14' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div> }
                                        
                                        {!details.video && <div className="thumb mb--30 picture-project">
                                            <img src={t(details.image)} alt="Portfolio Images"/>
                                        </div>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                

                 {/* Start COntact Area */}
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1">
                    <ContactThree contactImages="/assets/images/sami/sami_insta.jpg" contactTitle={t('hire_txt')} />
                </div>
            </div>
            {/* End COntact Area */}

            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}


            </React.Fragment>
        )
    }
}
export default withTranslation() (withRouter(PortfolioDetails));
