import i18next from 'i18next';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './en.json';
import fr from './fr.json';
import Cookies from 'universal-cookie'

const cookies = new Cookies()
export const enLanguage = 'en'
export const frLanguage = 'fr'

const language = cookies.get('locale') || navigator.language || (navigator).userLanguage || enLanguage;
//const language = 'en-US'; // TODO: hard-code for testing


export const setLanguage = (lng = language) => {
  const locale = cookies.get('locale')

  if (locale)
    lng = locale

  else {
    lng = language
  }

  i18next.changeLanguage(getLng(lng));
};

export const getLocale = () => {
  return language.replace('-', '_');
};

export const setLocale = (lng) => {
  cookies.set('locale', lng)
  setLanguage(lng)
}

export const getLng = (lng = language) => {
  return lng.split('-')[0] || enLanguage;
};

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: getLng(),
    resources: {
      en: {translation: en},
      fr: {translation: fr}
    },
  });
